body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --primary: #f94400;
  --primary-dark: #bd0000;
  --primary-light: #ff793a;

  --level1: #ffffff;
  --level2: #f5f5f5;
  --level3: #dddddd;

  --selected: #ff793a;

  --icon-fill: rgba(0, 0, 0, 0.6);

  --inset0: 4px;
  --inset1: 8px;
  --inset2: 16px;
  --inset3: 24px;
  --inset4: 32px;
  --inset5: 56px;
  --inset6: 64px;
  --inset7: 128px;
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 1.5em;
  }
}
